import {
    Card,
    List,
    ListItem,
    ListItemPrefix,
    Typography,
  } from "@material-tailwind/react";
  import React from "react";
  import conftemp from "../Docs/conference-template-a4.docx"
  import conftempLetter from "../Docs/conference-template-letter.docx"
  import latexInst from "../Docs/IEEEtran_HOWTO.pdf";
  import latex from "../Docs/conference-latex-template_10-17-19.zip";
  import ElsevierPPT from "../Docs/ICCRDA_2025_PPT_Template_Elsevier.pptx";
  import ElsevierPDF from "../Docs/ICCRDA_2025_PPT_Template_Elsevier.pdf";
  import IEEEPDF from "../Docs/ICCRDA_2025_PPT_Template_IEEE.pdf";
  import IEEEPPT from "../Docs/ICCRDA_2025_PPT_Template_IEEE.pptx";
  import paperPresentation from "../Docs/Paper Presentation Schedule_ICRDA 2025.pdf"
  import bib from "../Docs/IEEEtranBST2.zip"
  
  const Downloads = () => {
    return (
      <div className="w-80 mx-auto my-4">
          <h3 className="text-center font-bold text-xl text-white bg-blue-400 w-80">Downloads</h3>
        <marquee direction="up" className="bg-gray-100 h-80">
          <Card className="flex-col bg-white">
            <List>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={conftemp}>Conference Template - A4</a>
                  </Typography>                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={conftempLetter}>US Letter</a>

                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={latex}>LaTeX Template</a>
                  
                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={ElsevierPDF}>Elsevier SSRN Paper Template PDF</a>
                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={ElsevierPPT}>Elsevier SSRN Paper Template PPT</a>
                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={IEEEPDF}>IEEE Paper Template PDF</a>
                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={IEEEPPT}>IEEE Paper Template PPT</a>
                  </Typography>
                  
                </div>
              </ListItem>
              <ListItem>
                <div>
                  <Typography variant="h6" color="blue-gray">
                  <a href={paperPresentation}>ICCRDA-2025 Paper Presentation Schedule</a>
                  </Typography>
                  
                </div>
              </ListItem>
              
            </List>
          </Card>
        </marquee>
      </div>
    );
  };
  
  export default Downloads;
  